import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/auth/login/login.component';
import { RegisterComponent } from './components/auth/register/register.component';
import { ContentComponent } from "./components/pages/content/content.component";
import { HomeComponent } from './components/pages/home/home.component';
import { SettingsModule } from "./settings/settings.module";
import { IsLoggedInGuard, IsNotLoggedInGuard } from './shared/guards/is-logged-in.guard';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [IsLoggedInGuard],
    children: [
      { path: '', redirectTo: 'dashboard-cashin', pathMatch: 'full' },
      { path: 'operations-courier', component: ContentComponent, pathMatch: 'full' },
      { path: 'operations-invoice', component: ContentComponent, pathMatch: 'full' },
      { path: 'operations-cashin', component: ContentComponent, pathMatch: 'full' },
      { path: 'operations-cashout', component: ContentComponent, pathMatch: 'full' },
      { path: 'operation-cashout/:id', component: ContentComponent, pathMatch: 'full' },
      { path: 'monitor-bots', component: ContentComponent, pathMatch: 'full' },
      { path: 'bots-details/:id', component: ContentComponent, pathMatch: 'full' },
      { path: 'operation-cashin/:id', component: ContentComponent, pathMatch: 'full' },
      { path: 'activities-cashin-forcepayment', component: ContentComponent, pathMatch: 'full' },
      { path: 'activities-cashin-notifications', component: ContentComponent, pathMatch: 'full' },
      { path: 'activities-cashin-notifications/:id', component: ContentComponent, pathMatch: 'full' },
      { path: 'activities-cashin-partner-conciliation', component: ContentComponent, pathMatch: 'full' },
      { path: 'activities-cashin-adm-conciliation', component: ContentComponent, pathMatch: 'full' },
      { path: 'activities-cashin-extorno', component: ContentComponent, pathMatch: 'full' },
      { path: 'activities-cashin-refund', component: ContentComponent, pathMatch: 'full' },
      { path: 'activities-cashin-advances', component: ContentComponent, pathMatch: 'full' },
      { path: 'extorno/:id', component: ContentComponent, pathMatch: 'full' },
      { path: 'activities-cashin-sttlement', component: ContentComponent, pathMatch: 'full' },
      { path: 'sttlement-detail/:id', component: ContentComponent, pathMatch: 'full' },
      { path: 'activities-cashout-balance', component: ContentComponent, pathMatch: 'full' },
      { path: 'balance-detail/:id', component: ContentComponent, pathMatch: 'full' },
      { path: 'activities-cashout-payroll', component: ContentComponent, pathMatch: 'full' },
      { path: 'payroll-detail/:id', component: ContentComponent, pathMatch: 'full' },
      { path: 'reports-cashin-operations', component: ContentComponent, pathMatch: 'full' },
      { path: 'reports-cashin-settlements', component: ContentComponent, pathMatch: 'full' },
      { path: 'reports-cashout-operations', component: ContentComponent, pathMatch: 'full' },
      { path: 'reports-conciliations', component: ContentComponent, pathMatch: 'full' },
      {
        path: 'dashboard-cashin', component: ContentComponent, pathMatch: 'full',
        data: {
          breadcrumb: 'Cash In'
        }
      },
      { path: 'dashboard-cashout', component: ContentComponent, pathMatch: 'full' },
      { path: 'user-maintenance', component: ContentComponent, pathMatch: 'full' },
      { path: 'profile-maintenance', component: ContentComponent, pathMatch: 'full' },
      { path: 'maintenance-profile-detail', component: ContentComponent, pathMatch: 'full' },
      { path: 'maintenance-profile-detail/:id', component: ContentComponent, pathMatch: 'full' },
      { path: 'monitor-cashin', component: ContentComponent, pathMatch: 'full' },
      { path: 'monitor-cashout', component: ContentComponent, pathMatch: 'full'},
      { path: 'monitor-alertas', component: ContentComponent, pathMatch: 'full'},
      { path: 'reports-operations-conciliation', component: ContentComponent, pathMatch: 'full'},
      { path: 'reports-cashout-payroll', component: ContentComponent, pathMatch: 'full'},
      {
        path: 'settings',
        loadChildren: () => SettingsModule,
        data: {
          breadcrumb: 'Configuraciones'
        }
      },
    ],
  },
  { path: 'login', component: LoginComponent,canActivate: [IsNotLoggedInGuard], pathMatch: 'full' },
  { path: 'register', component: RegisterComponent,canActivate: [IsNotLoggedInGuard], pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
