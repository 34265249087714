import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { FileHandle } from 'src/app/directive/dragDrop.directive';
import { AccessService } from 'src/app/services/access.services';
import { ApiService } from 'src/app/services/api.service';
import { ListTableOptionsPayroll } from "../../../../../models/list-table-options-enums.model";
import { LoaderService } from "../../../../../services/loader.service";
import { StatusService } from "../../../../../services/status.service";
import { UtilsService } from "../../../../../services/utils.service";
import { TypeOfOperationEnum } from "../enums/type-of-operation.enum";
import { TypeOfOperationForToken } from "../interfaces/type-of-operation-for-token.interface";

@Component({
  selector: 'app-payroll',
  templateUrl: './payroll.component.html',
  styleUrls: ['./payroll.component.scss']
})
export class PayrollComponent implements OnInit {

  public dataInfo: any = this.getDataInfo();
  public dataInfoModal: any;
  public filters: any = this.getFilters();
  public titlePage: string = 'Actividades > Cash Out > Nóminas';

  dataFilters: any = {};
  titleModal: any = '';
  isVisible: boolean = false;
  isVisibleModalCode = false;
  isVisibleModalFiles = false;
  contentModal: any = '';
  operationNumber: any = '';
  listBanksPayroll: any;
  dataEventModal: any;
  okText: string = 'Generar';
  messageModal: any;
  dataMerchant: any;
  formModal: FormGroup;
  files: FileHandle[] = [];
  isUploadModalVisible = false;
  isLoading = false;
  selectedPayRollId = '';
  selectedPublicId = '';
  tokenPayroll = '';
  modalHeader: any;
  countries: any[] = []
  showModalToken = false;
  showRadioGroupInModal = false;
  titleModalToken = '';
  selectedRow: any
  functionModalToken = ''
  showQrGoogle = false;
  qrAuthGoogle = '';
  showModalTokenCancel = false;
  showModalTokenReprocess = false;
  freeTransation = true
  isSelectoptionCancel = true;
  reasonCancel = '';
  currentOptionPayroll: ListTableOptionsPayroll;

  constructor(
      private api: ApiService,
      private router: Router,
      private notification: NzNotificationService,
      private utilsService: UtilsService,
      private loaderService: LoaderService,
      private statusService: StatusService,
      private accessService: AccessService,
      private modalService: NzModalService,
  ) {
    this.formModal = this.createFormGroup();
   }

  createFormGroup() {
    return new FormGroup({
      Comercio: new FormControl('1001'),
      BankCode: new FormControl('1002'),
      Reference: new FormControl(''),
      Amount: new FormControl(''),
      CurrencyCode: new FormControl('USD')
    });
  }

  ngOnInit() {
    this.dataInfoModal = this.getDataInfoModal();
  }

  async getCountries() {
    this.countries = await this.utilsService.getDataCountry()
  }

  async getDataInfo(dataInfo: any = false) {
    const data = {
      service: 'payroll/list',
      data: []
    };

    const headers = [
      {
        'name': 'ID',
        'key': 'PublicID',
        'opc': true,
        'type': 'link',
        responsive: true
      },
      {
        'name': 'Banco',
        'key': 'ShortName',
        'opc': true
      },
      {
        'name': 'Total Record',
        'key': 'TotalRecords',
        'opc': true
      },
      {
        'name': 'Total Amount',
        'key': 'Amount',
        'opc': true
      },
      {
        'name': 'Currency',
        'key': 'CurrencyCode',
        'opc': true
      },
      {
        'name': 'Date',
        'key': 'created_at',
        'opc': true
      },
      {
        'name': 'Type',
        'key': 'processType',
        'opc': true,
        type: 'status',
      },
      {
        'name': 'Status',
        'key': 'LastStatus',
        'opc': true,
        type: 'status',
        responsive: true
      },
      {
        'name': 'Opciones',
        'key': 'list',
        'opc': false,
        'type': 'actions',
        'functions': [
          ListTableOptionsPayroll.ViewOutputFile,
          ListTableOptionsPayroll.ViewResponseFile,
          ListTableOptionsPayroll.UploadAnswerFile,
          ListTableOptionsPayroll.ReprocessPayroll,
          ListTableOptionsPayroll.CancelPayroll,
          ListTableOptionsPayroll.AuthorizeShipment,
          ListTableOptionsPayroll.SendToBank,
          ListTableOptionsPayroll.Processed,
        ],
      }
    ];

    if (dataInfo !== false) {
      data.service = 'payroll/listWithParams';
      data.data = dataInfo;
    }

    return {
      headers: headers,
      data: await this.api.api(data).toPromise(),
      source: 'payroll/list',
      getUrl: (id) => `/payroll-detail/${id}`,
    };

  }

  onChangeModal() {
    this.dataInfoModal = this.getDataInfoModal();
  }

  async getDataInfoModal() {

    const headers = [
      {
        'name': 'ID',
        'key': 'PublicId',
        'opc': true
      },
      {
        'name': 'Rode',
        'key': 'Amount',
        'opc': true
      },
      {
        'name': 'Bank',
        'key': 'Customer-InfoBank-CustomerBank',
        'opc': true
      },
      {
        'name': 'status',
        'key': 'LastStatus',
        'opc': true
      }
    ];
    return {
      headers: headers,
      data: this.getDetails(this.BankCode.value, 'payroll/listTransaction')
    };

  }

  async getDetails(id, service) {
    const data = {
      service: service,
      data: id
    };
    return await this.api.api(data).toPromise();
  }

  async getFilters() {

    const data = {
      service: 'listBanksCashout'
    };

    const dataMerchant = [];
    await this.api.api(data).toPromise().then((result: any) => {

      result.forEach(element => {
        dataMerchant.push({
          name: element.ShortName,
          value: element.BankCode,
        });
      });
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });

    await this.getCountries()

    this.dataMerchant = dataMerchant;
    return [

      {
        'name': 'Bancos',
        'key': 'bank_code',
        'type': 'select',
        'data':  this.dataMerchant
      },
      {
        'name': 'País',
        'key': 'country_code',
        'type': 'select',
        'data':  this.countries
      },
      {
        'name': 'Estado',
        'key': 'status',
        'type': 'select',
        'data': this.statusService.getPayrollStatus()
      },
      {
        'name': 'PayrollID',
        'key': 'public_id',
        'type': 'text'
      },
      {
        'name': 'Periodo',
        'key': ['from_date', 'to_date'],
        'type': 'period'
      },
      {
        'name': 'Nueva nomina',
        'key': 'new-payroll',
        'type': 'button',
      }

    ];

  }

  //EVENTS OUTPUT


  changeDataTable(data) {
    switch (data) {
      case 'new-payroll':
        this.getDataBankPayroll().then((result: any) => {
          this.listBanksPayroll = result;
          this.titleModal = 'Operaciones Pendientes';
          this.contentModal = 'generatePayroll';
          this.dataEventModal = 'generatePayroll';
        }).catch(error => {
          if (error.status === 401) {
            sessionStorage.setItem('ud', '');
            this.router.navigate(['/']);
          }
        });
        this.showModal();
        this.okText = 'Generar';
        break;
      case  'Upload':
        this.isUploadModalVisible = true;
        break;
      default:
        this.dataFilters = data
        this.dataInfo = this.getDataInfo(data);
        break;
    }

  }

  functions(data) {
    if (!data['data']) return
    this.selectedRow = data['data'];
    this.selectedPayRollId = data['data'].PayrollID;
    this.showRadioGroupInModal = false;
    this.currentOptionPayroll = data.function
    switch (data.function) {
      case ListTableOptionsPayroll.ViewOutputFile:
        if (data.data.files && data.data.files.length > 0) {
          this.isVisibleModalFiles = true;
        } else if (data.data.fileIn.UrlStorage){
          window.open(data.data.fileIn.UrlStorage, '_blank')
        } else {
          this.notification.create(
              'error',
              'Error de archivo',
              'Este Payroll no tiene un archivo de salida para mostrar'
          );
        }
        break
      case ListTableOptionsPayroll.ViewResponseFile:
        if (data.data.fileOut.UrlStorage){
          window.open(data.data.fileOut.UrlStorage, '_blank')
        } else {
          this.notification.create(
              'error',
              'Error de archivo',
              'Este Payroll no tiene un archivo de respuesta para mostrar'
          );
        }
        break
      case ListTableOptionsPayroll.UploadAnswerFile: {
          this.isUploadModalVisible = true;
          this.selectedPayRollId = data['data'].PayrollID;
      }
        break
      case ListTableOptionsPayroll.ReprocessPayroll:
        this.selectedPublicId = data['data'].PublicID;
        this.showModalTokenReprocess = true;
        break
      case ListTableOptionsPayroll.CancelPayroll:
        this.loaderService.updateLoading(true);
        this.showRadioGroupInModal = true;
        this.functionModalToken = ListTableOptionsPayroll.CancelPayroll;
        this.accessService.isUseTokenGoogle().subscribe(resul => {
          if(!resul.data){
            this.showQrGoogle = true;
            this.accessService.getQrGoogle(false).subscribe(resulQr => {
              this.qrAuthGoogle = resulQr.data;
              this.showModalTokenCancel = true;
              this.loaderService.updateLoading(false);
            });
          }else{
            this.showModalTokenCancel = true;
            this.showQrGoogle = false;
            this.loaderService.updateLoading(false);
          }
        }, error => {
          this.showModalTokenCancel = false;
          this.loaderService.updateLoading(false);
        })
        
        break
      case ListTableOptionsPayroll.SeeDetails:
        console.log(data.function)
        break
      case ListTableOptionsPayroll.AuthorizeShipment: {
        this.selectedPayRollId = data['data'].PayrollID;
        this.loaderService.updateLoading(true);
        this.titleModal = 'Código de verificación'
        this.accessService.isUseTokenGoogle().subscribe(resul => {
          if(!resul.data){
            this.showQrGoogle = true;
            this.accessService.getQrGoogle(false).subscribe(resulQr => {
              this.qrAuthGoogle = resulQr.data;
              this.isVisibleModalCode = true;
              this.loaderService.updateLoading(false);
            });
          }else{
            this.isVisibleModalCode = true;
            this.showQrGoogle = false;
            this.loaderService.updateLoading(false);
          }
        }, error => {
          this.isVisibleModalCode = false;
          this.loaderService.updateLoading(false);
        })
        break
      }
      case ListTableOptionsPayroll.Processed: {
        this.selectedPayRollId = data['data'].PayrollID;
        this.isVisibleModalCode = true;
        this.titleModal = 'Código de verificación'
      }
      break
      case ListTableOptionsPayroll.SendToBank: {
        this.selectedPayRollId = data['data'].PayrollID;
        this.titleModal = 'Código de verificación'
        this.isVisibleModalCode = true;
        break
  
      }

    }
  }

  

  functionsModal() {
    this.dataInfoModal = this.getDataInfoModal();
  }

  cancelPayroll(event) {
    this.loaderService.updateLoading(true);
    const route = `payroll/cancel`;
    const data = {
      payrollId: this.selectedPayRollId,
      transactionsProcess: event.transactionsProcess,
      token: event.otp,
      apiKey: this.accessService.tokenAccess,
      operation: TypeOfOperationEnum.CANCEL,
      reason: event.reason
    }
    this.api.queryPut(route, data).subscribe(
      () => {
        this.notification.create(
          'success',
          'Nómina anulada',
          'La nómina se ha anulado correctamente'
        );
        this.dataInfo = this.getDataInfo();
        this.loaderService.updateLoading(false)
      }, () => this.loaderService.updateLoading(false));
    this.reasonCancel = '';
    this.tokenPayroll = '';
    this.isSelectoptionCancel = true;
  }

  reprocess() {
    this.loaderService.updateLoading(true);
    const route = `payroll/update/${this.selectedPublicId}`;
    const data = {
      type: 1,
      reason: this.reasonCancel,
      transactionsProcess: true,
      transactions: [],
      apiKey: this.accessService.tokenAccess,
      token: this.tokenPayroll,
    }
    this.api.queryPut(route, data).subscribe(
      () => {
        this.loaderService.updateLoading(false);
        this.dataInfo = this.getDataInfo();
        this.notification.create('success', 'Operación exitosa', 'La operación se ha realizado correctamente');
        this.reprocessPayrollGoogleClose();
      },
      () => {
        this.loaderService.updateLoading(false);
        this.reprocessPayrollGoogleClose();
      }
    );
  }

  processedPayroll() {
    this.loaderService.updateLoading(true);
    const route = `payroll/processHomeBanking`;
    const data = {
      payrollId: this.selectedPayRollId,
    }
    this.api.queryPut(route, data).subscribe(
      () => {
        this.notification.create(
          'success',
          'Procesado',
          'Procesado correctamente'
        );
        this.dataInfo = this.getDataInfo(this.dataFilters);
        this.loaderService.updateLoading(false)
      }, () => this.loaderService.updateLoading(false));
  }

  sendToBankPayroll() {
    this.loaderService.updateLoading(true);
    const route = `payroll/updateStatusHomeBanking`;
    const data = {
      payrollId: this.selectedPayRollId,
    }
    this.api.queryPut(route, data).subscribe(
      () => {
        this.notification.create(
          'success',
          'Enviado al banco',
          'Enviado al banco correctamente'
        );
        this.dataInfo = this.getDataInfo(this.dataFilters);
        this.loaderService.updateLoading(false)
      } , () => this.loaderService.updateLoading(false));
  }

  eventOkModalToken(event) {
    if (event.type === ListTableOptionsPayroll.CancelPayroll) {
      this.cancelPayroll(event);
    }
  }


  generateToken(operation: TypeOfOperationEnum ) {
    this.tokenPayroll = '';
    const route = 'token'
    const data: TypeOfOperationForToken = {operation}
    return this.api.queryPost(route, data)
  }

  onOkModalToken() {
    if (this.currentOptionPayroll === ListTableOptionsPayroll.SendToBank) {
      this.validateToken(this.sendToBankPayroll.bind(this));
    }
    if (this.currentOptionPayroll === ListTableOptionsPayroll.AuthorizeShipment) {
      this.sendCode();
    }
    if (this.currentOptionPayroll === ListTableOptionsPayroll.Processed) {
      this.validateToken(this.processedPayroll.bind(this));
    }
  }

  validateToken(callback: () => void ) {
    this.accessService.validateAuthToken(this.tokenPayroll).subscribe((result: any) => {
      this.isVisibleModalCode = false;
      this.tokenPayroll = '';
      callback();
    }, (error) => {
      this.tokenPayroll = '';
      this.isVisibleModalCode = false;
      this.modalService.error({
        nzTitle: 'Mensaje de error',
        nzContent: `<ul> ${error.error.message} </ul>`,
        nzWidth: '500px',
      });
    });
  }

  cancelPayrollGoogle(){
    this.showModalTokenCancel = false;
    let event = {transactionsProcess : this.isSelectoptionCancel,
                otp: this.tokenPayroll,
                reason: this.reasonCancel};
    this.cancelPayroll(event);
  }

  reprocessPayrollGoogle(){
    this.showModalTokenReprocess = false;
    this.reprocess();
  }

  sendCode() {
    this.isLoading = true;
    const metaData = JSON.parse(sessionStorage.getItem('ud')).userDetails
    const route = 'payroll/send'
    const data = {
      payrollId:  this.selectedPayRollId,
      token: this.tokenPayroll,
      apiKey: this.accessService.tokenAccess,
      operation: 'PAYROLL_AUTHORIZE',
      userId: JSON.parse(sessionStorage.getItem('ud')).userDetails.profileid, //TODO : verificar que ya no este requiriendo userId y borrar esta linea
      metaData: {
        email: metaData.email,
        fullname: metaData.fullname
      }
    }
    this.api.queryPost(route, data).subscribe((resp: any) => {
      this.isVisibleModalCode = false;
      this.isLoading = false;
      if (!resp.error) {
        this.dataInfo = this.getDataInfo();
        this.notification.create(
            'success',
            'Payroll enviado',
            resp.message
        );
      } else {
        this.notification.create(
            'error',
            'Error',
            resp.message
        );
      }
    }, err => {
      this.isVisibleModalCode = false;
      this.isLoading = false;
    })
    this.tokenPayroll = '';
  }


  async getDataBankPayroll() {
    const data = {
      service: 'payroll/listBanks'
    };
    return await this.api.api(data).toPromise();
  }

  cancelCode() {
    this.isVisibleModalCode = false;
    this.tokenPayroll = '';
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    switch (this.dataEventModal) {
      case 'generatePayroll':
        this.newPayroll().then(() => {
          this.isVisible = false;

          // TODO
          this.dataInfo = this.getDataInfo();

        }).catch(error => {
          if (error.status === 401) {
            sessionStorage.setItem('ud', '');
            this.router.navigate(['/']);
          }
        });
        break;
      case 'ok':
        this.isVisible = false;
        break;
      default:
        break;
    }


  }

  async newPayroll() {
    const data = {
      service: 'payroll/create',
      data: {
        BankCode: this.BankCode.value,
        metadata: JSON.stringify(JSON.parse(sessionStorage.getItem('ud')).userDetails)
      }
    };
    return await this.api.api(data).toPromise();
  }

  async handleUploadRequest() {
    const data = {
      service: 'payroll/upload',
      data: {
        file: this.files,
        payrollid: this.selectedPayRollId,
        metadata: JSON.stringify(JSON.parse(sessionStorage.getItem('ud')).userDetails)
      }
    };
    return await this.api.api(data).toPromise();
  }

  handleCancel(): void {
    this.formModal = this.createFormGroup();
    this.isVisible = false;
  }

  filesDropped(files: FileHandle[]): void {
    this.files = files;
  }

  upload(): void {
    this.handleUploadRequest().then(() => {
      this.isUploadModalVisible = false;
      this.files = [];
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });
  }

  getNameOfUrl(url: string): string {
    return url.split('/').pop();
  }

  cancelUpload(): void {
    this.selectedPayRollId = '';
    this.files = [];
    this.isUploadModalVisible = false;
  }

  resetQr(){
    this.accessService.getQrGoogle(true).subscribe(resulQr => {
      this.showQrGoogle = true;
      this.qrAuthGoogle = resulQr.data;
    });
  }

  cancelPayrollGoogleClose(){
    this.showModalTokenCancel = false;
    this.reasonCancel = '';
    this.tokenPayroll = '';
    this.isSelectoptionCancel = true;
  }

  reprocessPayrollGoogleClose(){
    this.showModalTokenReprocess = false;
    this.reasonCancel = '';
    this.tokenPayroll = '';
    this.selectedPublicId = '';
  }

  get Comercio() { return this.formModal.get('Comercio'); }
  get BankCode() { return this.formModal.get('BankCode'); }
  get Reference() { return this.formModal.get('Reference'); }
  get Amount() { return this.formModal.get('Amount'); }
  get CurrencyCode() { return this.formModal.get('CurrencyCode'); }

}
