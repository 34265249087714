import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertsService } from '../../../services/alerts.service';
import { ApiService } from "../../../services/api.service";
import { WebSocketService } from '../../../services/web-socket.service';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public user: any;
  public ud: any;
  protected totalAlerts = 0;
  protected interval: any;

  constructor(
    private api: ApiService, 
    private webSocketService: WebSocketService, 
    private alertsService: AlertsService
    ) { }

  ngOnInit() {
    this.webSocketService.listenForMessages();
    if (sessionStorage.getItem('ud')){
      this.ud = JSON.parse(sessionStorage.getItem('ud'))
      this.user = this.ud ? this.ud.userDetails : null;
    }
    
    this.getAlerts();
    this.interval = setInterval(() => {
      this.getAlerts();
    }, 60000);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }
  
  getAlerts() {
    const filters = {
      startDate: '2021-01-01',
      endDate: new Date().toISOString().split('T')[0],
      status: 1,
    }
    this.alertsService.getAlerts(1, 5, filters).subscribe((result) => {
      this.totalAlerts = result.total;
    });
  }

  sendMsg() {
    this.webSocketService.sendMessage('Hi from Angular');
  }


  toogleMenu(){
    $('#nav').toggleClass('d-lg-block');
  }

  toogleMenuResponsive(){
    $('#nav').toggleClass('d-none');
    $('#nav').toggleClass('position-absolute');
  }

  exit(){
    this.api.logout();
  }



}
