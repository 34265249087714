import { HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NzModalService } from "ng-zorro-antd";
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { isUndefined } from 'util';
import { environment } from "../../environments/environment";
import { AccessService } from './access.services';
import { LoaderService } from "./loader.service";

const API = environment.api;

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  urlCore = API.urlCore;
  urlBase = API.urlBase;
  urlAcces = API.urlAcces
  apis = ['api-core-merchant', 'api-core-provider', 'api-core-bank', 'api-core-access'];

  constructor(private http: HttpClient, public router: Router, private modalService: NzModalService, private loaderService: LoaderService, private accessService: AccessService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.indexOf('auth/login') === -1) {
      if (this.isUrl(req.url, this.apis) && localStorage.getItem('authAccess')) {
        let tokenAccess = JSON.parse(localStorage.getItem('authAccess')).data.access_token;
        req = req.clone({
            setHeaders: {
                Authorization: `Bearer ${tokenAccess}`
            }
        });
        return next.handle(req).pipe(
            catchError((err) => {
                this.handleError(err);
                throw(err);
            })
        )
      }
      let idToken = '';
      if (sessionStorage.getItem('ud') && sessionStorage.getItem('ud') !== '') {
        idToken = JSON.parse(sessionStorage.getItem('ud')).token;
      }
      if (!idToken && localStorage.getItem('ud')) {
        idToken = JSON.parse(localStorage.getItem('ud')).token;
      }

      if (idToken) {
        const cloned = req.clone({
          headers: req.headers
            .set('Authorization', 'Bearer ' + idToken)

        });
        return next.handle(cloned).pipe(
            catchError((error: any) => {
              this.handleError(error);
              throw(error);
            })
        )
      } else {
        this.router.navigate(['/login']);
        return next.handle(req).pipe(
            catchError((error: any) => {
              this.handleError(error);
              throw(error);
            })
        )
      }

    } else {

      return next.handle(req);

    }

  }

    isUrl(url: string, array: string[]) {
        for (let i = 0; i < array.length; i++) {
            if (url.includes(array[i])) {
                return true;
            }
        }
        return false;
    }


  api(datos) {

    const header = {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${datos.token}`)
        .set('Content-Type', 'application/json')
    };

    let params = new HttpParams();
    switch (datos.service) {
      case 'cashin/operations':
        return this.http.get(`${this.urlCore + datos.service + this.param(datos.data)}`, header);
      case 'invoiceOperations':
        return this.http.get(`${this.urlCore + datos.service + this.param(datos.data)}`, header);
      case 'cashout/operations':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'bots':
          return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'bots_detail':
          return this.http.get(`${this.urlCore + datos.service + '/' + datos.data}`, header);
      case 'merchant_bank_account':
        return this.http.post(`${this.urlCore + datos.service + '/' + datos.id}`, datos.data);
      case 'merchant_commissions':
        return this.http.post(`${this.urlCore + datos.service + '/' + datos.id}`, datos.data);
      case 'nomenclatures':
        return this.http.post(`${this.urlCore + datos.service + '/' + datos.id}`, datos.data);
      case 'auth/logout':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'merchants':
        return this.http.get(`${this.urlCore + 'merchant'}`, header);
      case 'shopper':
        return this.http.get(`${this.urlCore + 'customers?CustomerTypeID=1'}`, header);
      case 'countries':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'partnerConciliations':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'partnerConciliations/listWithParams':
            return this.http.get(`${this.urlCore + 'partnerConciliations' + this.param(datos.data)}`, header);
      case 'partnerConciliations-put':
        return this.http.put(`${this.urlCore + 'partnerConciliations/' + datos.id}`, datos.data);
      case 'admConciliations':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'admConciliations-put':
        return this.http.put(`${this.urlCore + 'admConciliations' + '/' + datos.id}`, datos.data);
      case 'admConciliations/listWithParams':
        return this.http.get(`${this.urlCore + 'admConciliations' + this.param(datos.data)}`, header);
      case 'admConciliationsCategories':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'admConciliationsCategories-post':
        return this.http.post(`${this.urlCore + 'admConciliationsCategories'}`, datos.data);
      case 'admConciliationsSubcategories':
        return this.http.post(`${this.urlCore + datos.service + '/' + datos.id}`, datos.data);
      case 'merchant':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'merchant_type':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'extornos':
        return this.http.post(`${this.urlCore + datos.service}`, datos.data);
      case 'extornos-put':
        return this.http.put(`${this.urlCore + 'extornos' + '/' + datos.id}`, datos.data);
      case 'extornos-get':
        return this.http.get(`${this.urlCore + 'extornos' + this.param(datos.data)}`, header);
      case 'extornos-detail':
        return this.http.get(`${this.urlCore + 'extornos' + '/' + datos.id}`, header);
      case 'refunds':
        return this.http.get(`${this.urlCore + datos.service + this.param(datos.data)}`, header);
      case 'refunds-put':
        return this.http.put(`${this.urlCore + 'refunds/' + datos.id}`, datos.data);
      // case 'merchant-update':
        // return this.http.put(`${this.urlCore + 'merchant'}`, datos.data); //UPDATEDATA
      case 'listAllOperationCashinWithParams':
        return this.http.get(`${this.urlCore + 'cashin/operations' + this.param(datos.data)}`, header);
      case 'listAllOperationCashOutWithParams':
        return this.http.get(`${this.urlCore + 'cashout/operations' + this.param(datos.data)}`, header);
      case 'listBanksCashout':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'cashin/operations/detail':
        return this.http.get(`${this.urlCore + datos.service + '/' + datos.data}`, header);
      case 'cashout/operations/detail':
        return this.http.get(`${this.urlCore + datos.service + '/' + datos.data}`, header);
      case 'forcePayment/listOperation':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'forcePayment/listOperationWithParams':
        return this.http.get(`${this.urlCore + 'forcePayment/listOperation' + this.param(datos.data)}`, header);
      case 'listBanksCashin':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'forcePayment':
        return this.http.post(`${this.urlCore + datos.service}`, datos.data, header);
      case 'notification/listOperation':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'notification/listOperationWithParams':
        return this.http.get(`${this.urlCore + 'notification/listOperation' + this.param(datos.data)}`, header);
      case 'settlement/SettelmentDetail':
        return this.http.get(`${this.urlCore + datos.service + '/' + datos.data}`, header);
      case 'settlement/list':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'settlement/listWithParams':
        return this.http.get(`${this.urlCore + 'settlement/list' + this.param(datos.data)}`, header);
      case 'settlement/confirm':
        return this.http.post(`${this.urlCore + datos.service}`, datos.data);
      case 'balance/listBalance':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'balance/listBalanceWithParams':
        return this.http.get(`${this.urlCore + 'balance/listBalance' + this.param(datos.data)}`, header);
      case 'notification/execute/':
        return this.http.get(`${this.urlCore + datos.service + datos.data}`, header);
      case 'balance/create':
        return this.http.post(`${this.urlCore + datos.service}`, datos.data);
      case 'balance/detail':
        return this.http.get(`${this.urlCore + datos.service + '/' + datos.data}`, header);
      case 'payroll/list':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'payroll/listWithParams':
        return this.http.get(`${this.urlCore + 'payroll/list' + this.param(datos.data)}`, header);
      case 'payroll/listBanks':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'user/admin':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'user/merchant':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'profile/admin':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'profile/merchant':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'payroll/detail':
        return this.http.get(`${this.urlCore + datos.service + '/' + datos.data}`, header);
      case 'payroll/listTransaction':
        return this.http.get(`${this.urlCore + datos.service + '/' + datos.data}`, header);
      case 'payroll/create':
        return this.http.post(`${this.urlCore + datos.service}`, datos.data);
      case 'payroll/upload':
        return this.http.post(`${this.urlCore + datos.service}`, datos.data);
      case 'conciliation/info':
        return this.http.get(`${this.urlCore + datos.service + this.param(datos.data)}`, header);
      case 'listAllOperationCashin/download':
        window.location.href = this.urlCore + 'listAllOperationCashin/download' + this.param(datos.data);
      break;
      case 'settlement/download':
        window.location.href = this.urlCore + 'settlement/download' + this.param(datos.data);
      break;
      case 'listAllOperationCashOut/download':
        window.location.href = this.urlCore + 'listAllOperationCashOut/download' + this.param(datos.data);
      break;
      case 'getstadistict_cashin':
        return this.http.get(`${this.urlCore + datos.service + this.param(datos.data)}`, header);
      case 'getstadistict_cashout':
        return this.http.get(`${this.urlCore + datos.service + this.param(datos.data)}`, header);
      case 'getstadistict_line_cashin':
        return this.http.get(`${this.urlCore + datos.service + this.param(datos.data)}`, header);
      case 'getstadistict_line_cashout':
        return this.http.get(`${this.urlCore + datos.service + this.param(datos.data)}`, header);
      case 'merchant/detail':
        // return this.http.get(`${this.urlCore + datos.service + '/' + datos.data}`, header);
        return this.http.get(`${this.urlCore + datos.service + '/' + datos.data}`, header);
      case 'merchant_channel':
        return this.http.put(`${this.urlCore + datos.service + '/' + datos.id}`, datos.data);
      case 'merchant/activation_proccess':
        return this.http.post(`${this.urlCore + datos.service + '/' + datos.id}`, datos.data);
      case 'merchant_currency':
        return this.http.put(`${this.urlCore + datos.service + '/' + datos.id}`, datos.data);
      case 'bank':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'createBank':
        return this.http.post(`${this.urlCore + 'bank'}`, datos.data);
      case 'bankDetail':
        return this.http.get(`${this.urlCore + 'bank' + '/' + datos.data}`, header);
      case 'User':
        return this.http.post(`${this.urlCore + datos.service}`, datos.data);
      case 'profile':
        return this.http.post(`${this.urlCore + datos.service}`, datos.data);
      case 'monitorCashin': {
        const page = isUndefined(datos.data.page)? 1: datos.data.page;
        const queryParams = {page: page, size: datos.data.paginate, ...datos.data};
        return this.http.post(`${this.urlCore + datos.service + this.param(queryParams)}`, datos.data);
      }
      case 'monitorCashinExcel':
        return this.http.post(`${this.urlCore + datos.service}`, datos.data, {responseType: 'blob' as 'json'});
      case 'monitorCashout': {
        const page =   isUndefined(datos.data.page)? 1: datos.data.page;
        const body = {page: page, size: datos.data.paginate, ...datos.data};
        return this.http.post(`${this.urlCore + datos.service }`, this.formatBody(body));
      }
      case 'monitorCashoutExcel':
        return this.http.post(`${this.urlCore + datos.service}`, datos.data, {responseType: 'blob' as 'json'});
      case 'admConciliationExcel':
        params = params.append('paginate', isUndefined(datos.data.paginate)? null: datos.data.paginate );
        params = params.append('dateStart', isUndefined(datos.data.dateStart)? null: datos.data.dateStart );
        params = params.append('dateEnd', isUndefined(datos.data.dateEnd)? null: datos.data.dateEnd );
        params = params.append('merchantCode', isUndefined(datos.data.merchantId)? null: datos.data.merchantId );
        params = params.append('page', isUndefined(datos.data.page)? 1: datos.data.page );
        return this.http.get(`${this.urlCore + datos.service}`, {params: params, responseType:'blob'});
      case 'admConciliation':
        params = params.append('paginate', isUndefined(datos.data.paginate)? null: datos.data.paginate );
        params = params.append('dateStart', isUndefined(datos.data.dateStart)? null: datos.data.dateStart );
        params = params.append('dateEnd', isUndefined(datos.data.dateEnd)? null: datos.data.dateEnd );
        params = params.append('merchantCode', isUndefined(datos.data.merchantId)? null: datos.data.merchantId );
        params = params.append('page', isUndefined(datos.data.page)? 1: datos.data.page );
        return this.http.get(`${this.urlCore + datos.service}`, {params});
      case 'extract/total':
        params = params.append('from_date', isUndefined(datos.data.dateStart)? null: datos.data.dateStart );
        params = params.append('to_date', isUndefined(datos.data.dateEnd)? null: datos.data.dateEnd );
        params = params.append('size', '10' );
        //params = params.append('MerchantType', '6' );
        return this.http.get(`${this.urlCore +  datos.service + '/' + datos.data.merchant}`, {params});
      default:
      break;

    }


  }

  param(object){
    if (object) {
        const parameters = [];
        for (const property in object) {
            if (object.hasOwnProperty(property)) {
                if(object[property] && property != 'period'){
                  parameters.push(encodeURI(property + '=' + (object[property] ? object[property] : '')));
                }
            }
        }
        return '?'+parameters.join('&');
    }
    return ''
  }

  formatBody(data){
    // remove empty values or null
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if(data[key] == null || data[key] == ''){
          delete data[key];
        }
      }
    }
    return data;
  }

  getStatus() {
    return [
      {
        name: 'Expirado',
        value: 'o',
      },
      {
        name: 'Pendiente',
        value: '10',
      },
      {
        name: 'Anulado',
        value: '11',
      },
      {
        name: 'Pagado',
        value: '12',
      },
      {
        name: 'Notificado',
        value: '14',
      },
      {
        name: 'Liquidado',
        value: '16',
      },
      {
        name: 'Vencido',
        value: '13',
      },
      {
        name: 'Error Notificación',
        value: '15',
      }
    ];
  }

  getStatusCashOut() {
    return [
      {
        name: 'Anulado',
        value: 'o',
      },
      {
        name: 'Creado',
        value: '10',
      },
      {
        name: 'Aprobación de cliente',
        value: '11',
      },
      {
        name: 'En proceso (Payroll)',
        value: '12',
      },
      {
        name: 'Error en procesar',
        value: '13',
      },
      {
        name: 'Procesado',
        value: '14',
      },
      {
        name: 'Notificado',
        value: '15',
      },
      {
        name: 'Error al notificar',
        value: '16',
      },
      {
        name: 'Reprocesar',
        value: '17',
      }
    ];
  }



  getStatusByID(id) {
    switch (id) {
      case 'o':
        return 'Expirado';
      case '0':
        return 'Expirado';
      case '10':
        return 'Pendiente';
      case '11':
        return 'Anulado';
      case '12':
        return 'Pagado';
      case '14':
        return 'Notificado';
      case '16':
        return 'Liquidado';
      case '13':
        return 'Vencido';
      case '15':
        return 'Error Notificación';
      default:
        return 'Expirado';
    }

  }


  getStatusByIDCashOut(id) {
    switch (id) {
      case 'o':
        return 'Anulado';
      case '10':
        return 'Creado';
      case '11':
        return 'Aprobación de cliente';
      case '12':
        return 'En proceso (Payroll)';
      case '13':
        return 'Error en procesar';
      case '14':
        return 'Procesado';
      case '15':
        return 'Notificado';
      case '16':
        return 'Error al notificar';
      case '17':
        return 'Reprocesar';
      case '18':
        return 'Extornado';
      default:
      return 'Expirado';
    }
  }

  getStatusOperations(){
    return [
      {
        name: 'Expirado Anulado',
        value: '0',
      },
      {
        name: 'Pendiente',
        value: '10',
      },
      {
        name: 'Aprobado',
        value: '11',
      },
      {
        name: 'En proceso',
        value: '12',
      },
      {
        name: 'Error al procesar',
        value: '13',
      },
      {
        name: 'Procesado',
        value: '14',
      },
      {
        name: 'Notificado',
        value: '16',
      },
      {
        name: 'Reprocesar',
        value: '17',
      },
      {
        name: 'Extornado',
        value: '18',
      }
    ];
  }

  //1=transactions,2=Billing,3=Gross ,4=Net
  getTypeCashIn() {
    return [
      {
        name: 'Transacciones',
        value: '1',
      },
      {
        name: 'Facturación',
        value: '2',
      },
      {
        name: 'Bruto',
        value: '3',
      },
      {
        name: 'Neto',
        value: '4',
      },
    ];
  }

  queryGet<T>(route: string, params?: any, api?: string) {
    const apiURL = api ? api : this.urlCore;
    return this.http.get<T>(apiURL.concat(route) + this.param(params))
        .pipe(
            catchError((error: any) => {
              this.loaderService.updateLoading(false);
              this.handleError(error);
              throw(error);
            })
        );
  }

  queryDownload(route: string, params = null) {
    window.location.href = this.urlCore.concat(route) + this.param(params);
  }

  queryPost<T>(route: string, body: any, api?: string): Observable<T> {
    const apiURL = api ? api : this.urlCore;
    return this.http.post<T>(apiURL.concat(route), body)
  }

  queryPut(route: string, body: any) {
    return this.http.put(this.urlCore.concat(route), body)
  }

  private handleError(error: any): void {
    let message = '';
    let logout = false;
    let navigateTo = '';
    let title = error.error?.title ? error.error.title : 'Mensaje de error';

    if (error && error.url.includes('api-core-access.wepay4u.com')) {
      return
    }

    if (error.status === 404 || error.status === 405 ) {
      message = error.error.message ? error.error.message : 'El servidor no pudo procesar la solicitud!';
      logout = false;
    } else if (error.status === 500) {
      // logout = true;
    } else if (error.status === 422 || error.status === 400) {
      const result = error;

      if (result.error) {
        const error = result.error;
        for (const key of Object.keys(error)) {
          const errors = error[key]
          if (Array.isArray(errors) == true){
            errors.forEach((err) => {
              message += `<li>${err}</li>`;
            });
          }else if(typeof errors === 'object'){
            for (const key of Object.keys(errors)) {
              if (Array.isArray(errors[key]) == true) {
                errors[key].forEach((err) => {
                  message += `<li>${err}</li>`;
                });
              }
            }
          } else if(typeof errors === 'string'){
            message += errors + '<br>';
          }
        }
      } else {
        message = result.message || result.Message || result.data;
      }
    } else if (error.status == 401 || error.status == 403) {
      logout = true;
      navigateTo = '/login';
    } else if (error.status == 503) {
      message = 'Algo no va bien, parece que no tiene conexión!';
      logout = true;
    }

    if (message) {
      this.modalService.error({
        nzTitle: title,
        nzContent: `<ul> ${message} </ul>`,
        nzWidth: '500px',
      });
    }
    if (logout) {
      this.logout();
    } else if (navigateTo) {
      this.router.navigate([navigateTo]);
    }
  }

  logout(): void {
    const user = sessionStorage.getItem('ud') ? JSON.parse(sessionStorage.getItem('ud')) : null;
    
    const data = {
      email: user?.userDetails?.email || '', 
      service: 'cashin'
    }
    this.queryPost('logout', data, this.urlAcces).subscribe(
        () => {
          sessionStorage.clear();
          localStorage.clear();
          this.accessService.logout();
          this.router.navigate(['/login']);
        },
        () => {
    
          sessionStorage.clear();
          localStorage.clear();
          this.accessService.logout();
          this.router.navigate(['/login']);
        }
    );
  }

}
