import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { STATUS_OPERATION_CASH_OUT } from '../../../../constants/status.const';
import { ListTableOptionsPayroll } from "../../../../models/list-table-options-enums.model";
import { StatusService } from "../../../../services/status.service";

@Component({
  selector: 'app-model1',
  templateUrl: './model1.component.html',
  styleUrls: ['./model1.component.scss']
})
export class Model1Component implements OnInit, OnChanges {


  @Input() data: any;
  @Output() dataOutput = new EventEmitter<any>();

  infoTable: any;
  headers: any;
  getUrl: any;
  source: any;
  title: any = false;
  isVisible: boolean = false;
  titleModal: any;
  modalInfo: any;
  modalKeys: any = [];
  contentModal: any = '';
  classFunctions: any = '';
  sortName = '';
  sortValue = '';
  toggleSortValue = true;
  loading = false;
  isOptionList = false;
  dataPromise: any;
  typeActionCheckbox = '';

  constructor(private router: Router, private api: ApiService, private statusService: StatusService) { }

  ngOnInit() {
      this.getInfo();
  }

  getInfo() {
    this.loading = true;
    this.data.then((data: any) => {
      this.dataPromise = data
      if (data.title) {
        this.title = data.title;
      }

      this.headers = data.headers;
      this.getUrl = data.getUrl;

      if (this.source === 'activities-cashin-forcepayment') {
        this.source = 'operation-cashin';
        this.classFunctions = 'buttonPay';
      } else if (this.source === 'activities-cashin-sttlement') {
        this.classFunctions = 'buttonPay';
      } else {
        this.source = data.source;
      }
      this.infoTable = data.data.filter(e => e)
      
      this.loading = false;
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
        this.loading = false;
      }
    });
  }

  ngOnChanges() {
    this.getInfo();
  }

  getDataKeys(e, h) {
    return e[h.key.split('-')[0]][h.key.split('-')[1]][h.key.split('-')[2]];
  }

  // INFORMATION OF REQUEST AND RESPONSE
  viewModal(e) {

    this.titleModal = 'Información';
    this.modalInfo = JSON.parse(JSON.parse(e));
    this.contentModal = '';

    Object.keys(this.modalInfo).forEach(e => {
      switch (e) {
        case 'message':
          this.contentModal += `
                              <div class="d-flex">
                                <div class="col-6 font-weight-bold">Mensaje</div>
                                <div><b>:</b> ${this.modalInfo[e]}</div>
                              </div>
                              `;
          break;

        default:
          this.contentModal += `
                              <div class="d-flex">
                                <div class="col-6 font-weight-bold">${e}</div>
                                <div><b>:</b> ${this.modalInfo[e]}</div>
                              </div>
                              `;

          break;
      }
    });

    this.showModal();


  }

  // MODAL

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  // UTILITIES

  getNameStatus(e) {
    // if (e === 0) {
    //   e = 'o';
    // }
    return this.api.getStatusByID(e.toString());
  }

  getListFunctionsByStage(functions, stage) {
    const isRefunds = this.dataPromise.source === 'refunds';
    if (isRefunds) {
      switch (stage) {
        case 'Pendiente':
          return ['Ver datos', 'Autorizar', 'Rechazar'];
        case 'Autorizado':
          return ['Ver datos', 'Pagar', 'Rechazar'];
        case 'Receita':
          return ['Actualizar datos'];
        case 'Pagado':
          return ['Ver datos'];
        case 'Rechazado':
          return ['Ver datos'];
        default:
          return functions
      }
    } else {
      switch (stage) {
        case 'Pendiente':
          return ['Ver datos', 'Actualizar datos', 'Enviar a receita'];
        case 'Autorizado':
          return ['Ver datos', 'Pagar', 'Rechazar'];
        case 'Receita':
          return ['Actualizar datos'];
        case 'Pagado':
          return ['Ver datos'];
        case 'Rechazado':
          return ['Ver datos', 'Actualizar datos', 'Enviar a receita'];
        default:
          return functions
      }
    }
  }

  getListFunctionsByStagePayroll(functions, stage, data?: any) {    
    let typeProcessOptions = []
    if (data && data.processType === 'HOMEBANKING') {
      typeProcessOptions = stage === 2 ? [ListTableOptionsPayroll.SendToBank] : stage === 3
          ? [ListTableOptionsPayroll.Processed ]: [];
    }
    switch (stage) {
      case 0:
        return [ListTableOptionsPayroll.ViewOutputFile, ListTableOptionsPayroll.ViewResponseFile ];
      case 1:
        return [ListTableOptionsPayroll.ViewOutputFile, ListTableOptionsPayroll.ViewResponseFile ];
      case 2:
        const listOptions = [ListTableOptionsPayroll.ViewOutputFile, ListTableOptionsPayroll.ViewResponseFile, ListTableOptionsPayroll.CancelPayroll, ListTableOptionsPayroll.ReprocessPayroll, ...typeProcessOptions];
        if (data && data.processType !== 'HOMEBANKING') listOptions.push(ListTableOptionsPayroll.AuthorizeShipment);
        return listOptions;
      case 3:
        return [ListTableOptionsPayroll.ViewOutputFile, ListTableOptionsPayroll.ViewResponseFile, ListTableOptionsPayroll.ReprocessPayroll, ...typeProcessOptions];
      case 4:
        return [ListTableOptionsPayroll.ViewOutputFile, ListTableOptionsPayroll.ViewResponseFile];
      case 5:
        return [ListTableOptionsPayroll.ViewOutputFile, ListTableOptionsPayroll.ViewResponseFile];
      case 6:
        return [ListTableOptionsPayroll.ReprocessPayroll];
      case 7:
        return [ListTableOptionsPayroll.ViewOutputFile, ListTableOptionsPayroll.ViewResponseFile];
      default:
        return functions
    }
  }

  getActionsByStageAndSource(source, functions, stage, row?: any): any[] {
    switch (source) {
        case 'extorno':
          return this.getListFunctionsByStage(functions, stage);
        case 'payroll/list':
          return this.getListFunctionsByStagePayroll(functions, stage, row);
      case 'refunds':
        return this.getListFunctionsByStage(functions, stage);
      case 'payroll-detail':
        return stage !== 'En el banco' ? [] : this.getListFunctionsPayrollDetail(functions);
        default:
          return functions;
    }
  }

  getListFunctionsPayrollDetail(functions) {
    return functions.map(f => f.label)
  }


  getNameStatusCashOut(e) {
    if (e === 0) {
      e = 'o';
    }
    return this.api.getStatusByIDCashOut(e.toString());
  }

  getColorStatusCashOut(e) {
    if (e === 0) {
      e = 'o';
    }
    return STATUS_OPERATION_CASH_OUT.find(item => item.value === e.toString()).color;
  }

  getStatusBalance(value) {
    const status = {
      0: {color: 'red', label: 'Anulado'},
      1: {color: 'orange', label: 'Pendiente'},
      2: {color: 'blue', label: 'Aprobado'},
    }

    return status[value] ? status[value] : {color: 'gray', label: 'Sin estado'};
  }

  getColorStatusPayroll(value) {
    const status = this.statusService.getPayrollStatus()
    const colors = this.statusService.getPayrollStatusColor()

    const state = status.find(item => item.value === Number(value)) || {name: 'Sin estado'}
    const color = colors.find(color => color.statusId === Number(value)) || {color: 'gray'};
    return {color: color.color, label: state.name}
  }

  getColorStatusOperation(value) {
    const status = {
      '0': {color: 'red', label: 'Expirado'},
      '10': {color: 'orange', label: 'Creado'},
      '11' : {color: 'red', label: 'Anulado por comercio'},
      '12': {color: 'green', label: 'Recaudado en el banco'},
      '13': {color: 'red', label: 'Recaudo con rechazo'},
      '14': {color: 'cyan', label: 'Notificado al comercio'},
      '15': {color: 'red', label: 'Error Notificación'},
      '16' : {color: 'lime', label: 'Liquidado'},
      'Expirado' : {color: 'red', label: 'Expirado'},
      'Creado' : {color: 'orange', label: 'Creado'},
      'Anulado por comercio'  : {color: 'red', label: 'Anulado por comercio'},
      'Recaudado en el banco' : {color: 'green', label: 'Recaudado en el banco'},
      'Recaudo con rechazo' : {color: 'red', label: 'Recaudo con rechazo'},
      'Notificado al comercio' : {color: 'cyan', label: 'Notificado al comercio'},
      'Error Notificación' : {color: 'red', label: 'Error Notificación'},
      'Liquidado'  : {color: 'lime', label: 'Liquidado'},
    }
    return status[value] ? status[value] : {color: 'gray', label: value}
  }

  getColorStatus(value){
    const status = {
      Procesado: 'cyan',
      Observaciones: 'green',
      'En el banco': 'orange',
      'En proceso': 'orange',
      Anulado: 'red',
      'Rechazado por el banco' : 'red',
      'Procesado con rechazos' : 'magenta',
      'Creado' : 'orange',
      'En Proceso' : 'pink',
      'Pagado' : 'green',
      'Expirado' : 'red',
      'Pendiente Aprobación' : 'volcano',
      'Aprobación del  Cliente' : 'cyan',
      'Waiting' : 'green',
      'Check' : 'volcano',
      'Off' : 'red',
      'Factura Creada' : 'cyan',
      'Factura con error' : 'red',
      'H2H': 'purple',
      'HOMEBANKING': 'pink',
    }
    return status[value] ? status[value] : 'gray'
  }

  getStatus(key, source?: string) {
    switch (source) {
      case 'operation-cashin':
        return this.getColorStatusOperation(key);
      case 'operation-cashout':
        return {color : this.getColorStatusCashOut(key), label : this.getNameStatusCashOut(key)};
      case 'payroll/list':
        return this.getColorStatusPayroll(key);
      case 'balance-detail-historical':
        return this.getStatusBalance(key);
      default:
        return {color : this.getColorStatus(key), label : key};
    }
  }

  getButtons(item, status) {
    const listButtons = [...item.buttons];
    if (item.module === 'bots') {
      if (status === 'Waiting') {
        listButtons.splice(0, 1);
        return listButtons
      }
      if (status === 'Off' || status === 'Check') {
        listButtons.splice(1, 1);
        return listButtons;
      }
      if (status === 'InProgress') {
        listButtons.splice(0, 1);
        return listButtons;
      }
    }
    return listButtons
  }

  actionButton(action){
    this.dataOutput.emit(action);
  }

  getColor(e){
    let color = "#000000"

    if(e === 'Off'){color = "#b20000";}
    if(e === 'Waiting'){color = "#00ce2b";}
    if(e === 'InProgress'){color = "#E59D20";}
    if(e === 'Check'){color = "#b20000";}

    if(e === 'Entrada'){color = "#00ce2b";}
    if(e === 'Salida'){color = "#b20000";}

    if(e >= 3){color = "#b20000";}

    return color
  }

  getColorDetail(e){
    let color = "#000000"

    if(e === 'Pending'){color = "#E59D20";}
    if(e === 'Successful'){color = "#00ce2b";}
    if(e === 'Do not process'){color = " #cfcccb";}

    return color
  }

  sort(sort: { key: string; value: string }): void {
    this.sortValue = sort.value;
  }


  test() {
    this.toggleSortValue = !this.toggleSortValue;
    this.infoTable.sort(function(a, b)  {
      const x = b.PublicId.toLowerCase();
      const y = a.PublicId.toLowerCase();
      if (x < y) {return -1;}
      if (x > y) {return 1;}
      return 0;
      });
  }

  //  FUNCTIONS EVENTS

  functionsEvent(f, data, event: any = false) {
    const info = {
      function: f,
      data: data,
      userDetails: JSON.parse(sessionStorage.getItem('ud')).userDetails,
      event: event
    };
    this.dataOutput.emit(info);
  }

  getIdRedirect(data, source) {
    if (data) {
      switch (source) {
        case 'extorno': {
          const route = '/' + source + '/' + data.ExtornoID
          return [route]
        }
        case 'refunds': {
          const route = '/' + source + '/' + data.RefundPublicID
          return [route]
        }
        case 'payroll/list': {
          const route = '/' + 'payroll-detail' + '/' + data.PublicID
          return [route]

        }
        default: {
          const route = '/' + source + '/' + (data.PublicId ? data.PublicId : data.PublicID)
          return [route]
        }
      }
    }
  }


 // separate a string by dots and access the data object
  getData(data, key) {
    const keys = key.split('.');
    let value = data;
    for (let i = 0; i < keys.length; i++) {
      value = value[keys[i]];
    }
    return value;
  }

  validateDisability(key, data) {
    return data.LastStatus !== 'En el banco';
  }

  validateDisabilityBalance(key, data) {
    return data.LastStatus !== 1;
  }

  hasCheckbox(element) {
    if (element.type === 'checkbox') {
      if (this) {
        this.getTypeActionCheckbox(element.name);
      }
      return true;
    }
    return false;

  }

  getTypeActionCheckbox(name){
    this.typeActionCheckbox = name
  }

  getAlertMonitor(data){
    if(data && data.notificationError){
      if (data.notificationError > 5){
        return true;
      }
    }
    return false;
  }

  getActionsOfFunctions() :any[] {
    return this.headers.find(x => x.type === 'checkbox').functions
  }
}

