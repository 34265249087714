import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import es from '@angular/common/locales/es';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/auth/login/login.component';
import { RegisterComponent } from './components/auth/register/register.component';
import { MerchantsComponent } from './components/configurations/merchants/merchants.component';
import { ForcePaymentsComponent } from './components/content/activities/cashin/force-payments/force-payments.component';
import { NotificationsComponent } from './components/content/activities/cashin/notifications/notifications.component';
import { SttlementDetailComponent } from './components/content/activities/cashin/sttlement-detail/sttlement-detail.component';
import { SttlementComponent } from './components/content/activities/cashin/sttlement/sttlement.component';
import { BalanceDetailComponent } from './components/content/activities/cashout/balance-detail/balance-detail.component';
import { BalanceComponent } from './components/content/activities/cashout/balance/balance.component';
import { PayrollDetailComponent } from './components/content/activities/cashout/payroll-detail/payroll-detail.component';
import { PayrollComponent } from './components/content/activities/cashout/payroll/payroll.component';
import { DashboardCashoutComponent } from './components/content/dashboard/dashboard-cashout/dashboard-cashout.component';
import { DashboardComponent } from './components/content/dashboard/dashboard.component';
import { CashinComponent } from './components/content/operations/cashin/cashin.component';
import { CashoutComponent } from './components/content/operations/cashout/cashout.component';
import { DetailsComponent } from './components/content/operations/details/details.component';
import { OperationsComponent } from './components/content/reports/cashin/operations/operations.component';
import { SettlementsComponent } from './components/content/reports/cashin/settlements/settlements.component';
import { OperationsCashoutComponent } from './components/content/reports/cashout/operations/operations.component';
import { ConciliationsComponent } from './components/content/reports/conciliations/conciliations.component';
import { BanksComponent } from './components/content/settings/banks/banks.component';
import { ProfileMaintenanceComponent } from './components/content/settings/profile-maintenance/profile-maintenance.component';
import { UserMaintenanceComponent } from './components/content/settings/user-maintenance/user-maintenance.component';
import { HomeComponent } from './components/pages/home/home.component';
import { CommissionCardComponent } from './components/partials/commission-card/commission-card.component';
import { Detail1Component } from './components/partials/details/detail1/detail1.component';
import { FooterComponent } from './components/partials/footer/footer.component';
import { HeaderComponent } from './components/partials/header/header.component';
import { MainMenuComponent } from './components/partials/main-menu/main-menu.component';
import { NavComponent } from './components/partials/nav/nav.component';
import { Model1Component } from './components/partials/tables/model1/model1.component';
import { ApiService } from './services/api.service';
// tslint:disable-next-line:max-line-length
import { UsersComponent } from './components/configurations/users/users.component';
import { BanksDetailComponent } from './components/content/settings/banks-detail/banks-detail.component';
import { CommerceComponent } from './components/content/settings/commerce/commerce.component';
import { ProfileMaintenanceDetailComponent } from './components/content/settings/profile-maintenance-detail/profile-maintenance-detail.component';

import { DragDirective } from 'src/app/directive/dragDrop.directive';

import { ServiceWorkerModule } from '@angular/service-worker';
import { NgIdleModule } from '@ng-idle/core';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { environment } from '../environments/environment';
import { ActivitiesDetailsComponent } from './components/content/activities/activities-details/activities-details.component';
import { ADMConciliationComponent } from './components/content/activities/cashin/adm-conciliation/adm-conciliation.component';
import { AdvancesComponent } from './components/content/activities/cashin/advances/advances.component';
import { ExtornoComponent } from './components/content/activities/cashin/extorno/extorno.component';
import { PartnerConciliationComponent } from './components/content/activities/cashin/partner-conciliation/partner-conciliation.component';
import { RefundComponent } from './components/content/activities/cashin/refund/refund.component';
import { ModalTokenComponent } from './components/content/activities/cashout/modals/cancel-payroll/modal-token.component';
import { AlertsComponent } from './components/content/monitor/alerts/alerts.component';
import { BotDetailsComponent } from './components/content/monitor/bot-details/bot-details.component';
import { BotsComponent } from './components/content/monitor/bots/bots.component';
import { TransationCashinComponent } from './components/content/monitor/transation-cashin/transation-cashin.component';
import { TransationCashoutComponent } from './components/content/monitor/transation-cashout/transation-cashout.component';
import { CourierComponent } from './components/content/operations/courier/courier.component';
import { InvoiceComponent } from './components/content/operations/invoice/invoice.component';
import { ConsiliationsCashoutComponent } from './components/content/reports/cashout/conciliations/conciliations-cashout.component';
import { ReportsPayrollComponent } from "./components/content/reports/cashout/payroll/payroll.component";
import { ContentComponent } from './components/pages/content/content.component';
import { OnlyNumberDirective } from './components/partials/otp-input/otp-input.component';
import { Model1PaginateComponent } from './components/partials/tables/model1-paginate/model1-paginate.component';
import { TableAdmConciliationComponent } from './components/partials/tables/table-adm-conciliation/table-adm-conciliation.component';
import { TableAlertsComponent } from './components/partials/tables/table-alerts/table-alerts.component';
import { TablePartnerConciliationComponent } from './components/partials/tables/table-partner-conciliation/table-partner-conciliation.component';
import { DataKeysPipe } from './pipes/data-keys/data-keys.pipe';
import { SharedModule } from "./shared/shared.module";

registerLocaleData(es);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    DashboardComponent,
    NavComponent,
    HeaderComponent,
    FooterComponent,
    MainMenuComponent,
    OnlyNumberDirective,
    CashinComponent,
    Model1Component,
    CashoutComponent,
    DetailsComponent,
    Detail1Component,
    ForcePaymentsComponent,
    NotificationsComponent,
    SttlementComponent,
    SttlementDetailComponent,
    BalanceComponent,
    BalanceDetailComponent,
    PayrollComponent,
    PayrollDetailComponent,
    OperationsComponent,
    SettlementsComponent,
    ConciliationsComponent,
    OperationsCashoutComponent,
    DashboardCashoutComponent,
    MerchantsComponent,
    BanksComponent,
    UsersComponent,
    DragDirective,
    CommerceComponent,
    CommissionCardComponent,
    BanksDetailComponent,
    UserMaintenanceComponent,
    ProfileMaintenanceComponent,
    ProfileMaintenanceDetailComponent,
    BotsComponent,
    BotDetailsComponent,
    PartnerConciliationComponent,
    TablePartnerConciliationComponent,
    ADMConciliationComponent,
    TableAdmConciliationComponent,
    ExtornoComponent,
    RefundComponent,
    ActivitiesDetailsComponent,
    ContentComponent,
    ModalTokenComponent,
    InvoiceComponent,
    CourierComponent,
    TransationCashinComponent,
    TransationCashoutComponent,
    ConsiliationsCashoutComponent,
    Model1PaginateComponent,
    ReportsPayrollComponent,
    AdvancesComponent,
    DataKeysPipe,
    AlertsComponent,
    TableAlertsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxChartsModule,
    NgCircleProgressModule.forRoot({}),
    NgIdleModule.forRoot(),
    NgApexchartsModule,
    CommonModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production})
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiService,
      multi: true,
    },
    CookieService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
