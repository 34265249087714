import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { io } from "socket.io-client";

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  public alertsSubject: Subject<string> = new Subject<string>();
  private socket;

  constructor() {
    this.socket = io('https://dev-api-core-admin.wepay4u.com', {
      upgrade: false,
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: Infinity,
      extraHeaders: {
        Authorization: 'MTIzNDU2Nzg5Og=='
      }
    }); // Dirección del backend
    this.socket.on('connect', () => {
      console.log('Conectado al servidor WebSocket');
    });
  }

  sendMessage(msg: string) {
    this.socket.emit('message', msg);
  }

  listenForMessages() {
    this.socket.on('readAlert', (data) => {
      console.log('Received message from API: ', data);
      this.alertsSubject.next(data);
    });
  }
}
