import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AntDesignModule } from '../ant-design/ant-design.module';
import { FilterInputsComponent } from '../components/partials/filters/filter-inputs/filter-inputs.component';
import { Filter1Component } from '../components/partials/filters/filter1/filter1.component';
import { OtpInputComponent } from '../components/partials/otp-input/otp-input.component';
import { MsgErrorComponent } from "./components/msg-error/msg-error.component";
import { NoPasteDirective } from './directives/no-paste.directive';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';


@NgModule({
    declarations: [
        MsgErrorComponent,
        Filter1Component,
        FilterInputsComponent,
        OnlyNumbersDirective,
        NoPasteDirective,
        OtpInputComponent
    ],
    imports: [
        CommonModule,
        AntDesignModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    exports: [
        MsgErrorComponent,
        Filter1Component,
        AntDesignModule,
        FormsModule,
        ReactiveFormsModule,
        OnlyNumbersDirective,
        NoPasteDirective,
        OtpInputComponent
    ]
})
export class SharedModule {
}
